<template>
  <div class="tools-name">视频专区</div>
  <hr color="#f0f0f0" />
  <a-row :gutter="{ xs: 8, sm: 16, md: 24, lg: 32 }">
    <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="4" :order="1">
      <a class="tools-hover" href="http://xijing.tv/" target="blank">
        <div class="icon"
          ><img class="tools-img" src="@/assets/relax/xjtv.png"
        /></div>
        <div class="content">
          <span class="title">xijing</span>
          <p class="description">电影片源丰富支持百度云</p>
        </div>
      </a>
    </a-col>
    <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="4" :order="2">
      <a class="tools-hover" href="https://www.1993s.top/" target="blank">
        <div class="icon"
          ><img class="tools-img" src="@/assets/relax/zxzj.ico"
        /></div>
        <div class="content">
          <span class="title">在线之家</span>
          <p class="description">在线观看最新美剧韩剧</p>
        </div>
      </a>
    </a-col>
    <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="4" :order="3">
      <a class="tools-hover" href="https://www.meiju11.com/" target="blank">
        <div class="icon"
          ><img class="tools-img" src="@/assets/relax/mj.ico"
        /></div>
        <div class="content">
          <span class="title">人人美剧</span>
          <p class="description">专业提供高清好看的人人美剧</p>
        </div>
      </a>
    </a-col>
    <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="4" :order="4">
      <a class="tools-hover" href="https://www.bttwo.com/" target="blank">
        <div class="icon"
          ><img class="tools-img" src="@/assets/video/bttwo.png"
        /></div>
        <div class="content">
          <span class="title">两个BT</span>
          <p class="description">各种高清电影在线观看</p>
        </div>
      </a>
    </a-col>
    <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="4" :order="5">
      <a class="tools-hover" href="https://www.idybee.com" target="blank">
        <div class="icon"
          ><img class="tools-img" src="@/assets/relax/dybee.png"
        /></div>
        <div class="content">
          <span class="title">电影蜜蜂</span>
          <p class="description">在线观看最新美剧韩剧</p>
        </div>
      </a>
    </a-col>
    <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="4" :order="6">
      <a class="tools-hover" href="https://yyets.dmesg.app/" target="blank">
        <div class="icon"
          ><img class="tools-img" src="@/assets/relax/yyet.png"
        /></div>
        <div class="content">
          <span class="title">人人影视</span>
          <p class="description">人人影视下载分享</p>
        </div>
      </a>
    </a-col>
    <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="4" :order="7">
      <a class="tools-hover" href="http://tool.liumingye.cn/video/" target="blank">
        <div class="icon"
          ><img class="tools-img" src="@/assets/video/liumingye.ico"
        /></div>
        <div class="content">
          <span class="title">VIP视频解析</span>
          <p class="description">VIP在线视频解析，支持各大平台</p>
        </div>
      </a>
    </a-col>
    <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="4" :order="9">
      <a class="tools-hover" href="https://www.nunuyy.cc/" target="blank">
        <div class="icon"
          ><img class="tools-img" src="@/assets/resources/nu.ico"
        /></div>
        <div class="content">
          <span class="title">努努影院</span>
          <p class="description">努努影院在线观影</p>
        </div>
      </a>
    </a-col>
    <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="4" :order="10">
      <a class="tools-hover" href="http://data.viggo.site/" target="blank">
        <div class="icon"
          ><img class="tools-img" src="@/assets/video/viggo.jpg"
        /></div>
        <div class="content">
          <span class="title">数据可视化</span>
          <p class="description">旨在解决快速、简单生成动态柱状图图表</p>
        </div>
      </a>
    </a-col>
    <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="4" :order="11">
      <a class="tools-hover" href="https://www.videvo.net/" target="blank">
        <div class="icon"
          ><img class="tools-img" src="@/assets/video/videvo.ico"
        /></div>
        <div class="content">
          <span class="title">视频素材下载</span>
          <p class="description">制作视频的免费素材</p>
        </div>
      </a>
    </a-col>
    <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="4" :order="12">
      <a class="tools-hover" href="https://www.savetweetvid.com/zh" target="blank">
        <div class="icon"
          ><img class="tools-img" src="@/assets/resources/savetd.ico"
        /></div>
        <div class="content">
          <span class="title">Twitter视频下载器</span>
          <p class="description">将Twitter视频下载到Mp4和Mp3</p>
        </div>
      </a>
    </a-col>
    <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="4" :order="13">
      <a class="tools-hover" href="https://tool.liumingye.cn/avbv/" target="blank">
        <div class="icon"
          ><img class="tools-img" src="@/assets/media/bili.ico"
        /></div>
        <div class="content">
          <span class="title">B站AV/BV互转</span>
          <p class="description">哔哩哔哩 AV/BV 互转</p>
        </div>
      </a>
    </a-col>
    <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="4" :order="14">
      <a class="tools-hover" href="https://myvoiceyourface.com/" target="blank">
        <div class="icon"
          ><img class="tools-img" src="@/assets/video/myvoiceyourface.png"
        /></div>
        <div class="content">
          <span class="title">视频换脸</span>
          <p class="description">替换视频中人物的脸</p>
        </div>
      </a>
    </a-col>
    <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="4" :order="15">
      <a class="tools-hover" href="https://lv.ulikecam.com/" target="blank">
        <div class="icon"
          ><img class="tools-img" src="@/assets/resources/jy.jpg"
        /></div>
        <div class="content">
          <span class="title">剪映</span>
          <p class="description">最好用的视频剪辑工具</p>
        </div>
      </a>
    </a-col>
    <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="4" :order="16">
      <a class="tools-hover" href="http://anchor.avatarworks.com/" target="blank">
        <div class="icon"
          ><img class="tools-img" src="@/assets/video/avatarworks.ico"
        /></div>
        <div class="content">
          <span class="title">黑镜AI</span>
          <p class="description">AI短视频生成平台</p>
        </div>
      </a>
    </a-col>
    <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="4" :order="17">
      <a class="tools-hover" href="https://www.jijidown.com/" target="blank">
        <div class="icon"
          ><img class="tools-img" src="@/assets/video/jiji.ico"
        /></div>
        <div class="content">
          <span class="title">唧唧下载器</span>
          <p class="description">bilibili视频|弹幕在线下载</p>
        </div>
      </a>
    </a-col>
  </a-row>
</template>
<script>
  export default {};
</script>
<style></style>
